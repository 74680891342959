<script setup lang="ts">
const props = defineProps<{
	isOpened?: boolean;
	content?: string;
	title?: string;
	subTitle?: string;
	subTitleMobile?: string;
	buttonText?: string;
	imgPath?: string;
	isLastStepIntroMode?: boolean;
}>();

const emit = defineEmits<{
	(event: "complete", value?: string): void;
	(event: "close", value?: string): void;
	(event: "dontShow", value: boolean): void;
}>();

const { isDesktop } = useDevice();

const dontShowAgain = ref(false);

const updateDontShowAgain = (newValue: boolean) => {
	emit("dontShow", newValue);
};

const handleClose = () => {
	if (props.isLastStepIntroMode) {
		emit("dontShow", true);
	}

	emit("close");
};
</script>

<template>
	<div class="onboard-popper">
		<APopper class="popper-wrapper" placement="top" :show="isOpened" arrow>
			<template #default>
				<span class="popper-mark" />
			</template>
			<template #content>
				<div class="popper-window" @click="emit('complete')">
					<div class="popper-content">
						<AText variant="taipei" class="text-clara text-tlalnepantla popper-title" :modifiers="['bold']">{{
							title
						}}</AText>
						<AText class="text-clara">{{ isDesktop ? subTitle : subTitleMobile }}</AText>
						<div v-if="!!imgPath" class="popper-img-wrapper">
							<NuxtImg :src="imgPath" class="popper-img" width="584" height="80" format="avif" alt="popper-img" />
						</div>
						<AText
							v-if="!isLastStepIntroMode"
							variant="topeka"
							class="label-wrapper text-clara"
							:modifiers="['bold']"
							as="div"
						>
							<MCheckbox
								:modelValue="dontShowAgain"
								class="checkbox-field"
								noAnimation
								@update:modelValue="updateDontShowAgain"
							/>
							{{ buttonText }}
						</AText>
					</div>
					<NuxtIcon class="close-popper" name="20/close-pop" filled @click.stop="handleClose" />
				</div>
			</template>
		</APopper>
	</div>
</template>

<style lang="scss" scoped>
.label-wrapper {
	display: inline-flex;
	align-items: center;
	gap: 12px;
	margin-top: auto;
}
.popper-content {
	display: flex;
	flex-direction: column;
	height: 100%;
	gap: 8px;
}
.onboard-popper {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 100001;
}
.popper-title {
	padding-right: 24px;
	line-height: 1.25;
}

.popper-wrapper {
	&:deep(.popper) {
		display: flex;
		width: 332px;
		min-height: 122px;
		background: var(--coyoacan);
		border-radius: 12px;
		padding: 0;
	}

	&:deep(.popper > #arrow::before) {
		background: var(--coyoacan);
	}

	@include media-breakpoint-down(sm) {
		max-width: 100% !important;
	}
}

.close-popper {
	padding: 5px;
	position: absolute;
	top: 5px;
	right: 5px;
	font-size: 24px;
	cursor: pointer;
	z-index: 1;

	&:deep(svg path) {
		fill: var(--clara);
	}
}

.popper-window {
	padding: 12px;
}

.popper-mark {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: var(--caxias);

	&::before {
		content: "";
		background: var(--coyoacan);
		width: 16px;
		height: 16px;
		border-radius: 50%;
	}
}

.popper-img {
	width: 180px;
	height: 40px;
	object-fit: cover;
}

.popper-img-wrapper {
	display: flex;
	justify-content: center;
}
</style>
